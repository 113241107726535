<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

defineProps<{
  title?: string;
  vimeoVideoUrl?: string;
}>();

defineEmits<(e: "update:modelValue", value: boolean) => void>();
</script>

<template>
  <VueFinalModal
    teleport-to="#teleports"
    class="modal"
    :class="{ 'flex items-center justify-center': !!vimeoVideoUrl }"
    :content-class="{ 'modal-content': true, 'video': !!vimeoVideoUrl }"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    @closed="$emit('update:modelValue', false)"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <div
      v-if="vimeoVideoUrl"
      class="video-content"
    >
      <UiVimeoPlayer
        ref="player"
        class="player"
        :video-url="vimeoVideoUrl"
        :autoplay="true"
      />
    </div>
    <slot />
    <div
      class="modal-close"
      aria-label="Close"
      @click="$emit('update:modelValue', false)"
    >
      <NuxtIcon
        name="cross"
        class=""
      />
    </div>
  </VueFinalModal>
</template>

<style lang="scss">
.modal {
  .modal-content {
    &.video {
      width: 1280px;
      max-width: 80%;

      @apply bg-ui-grey3;
    }

    &:not(.video) {
      position: relative;
      overflow: auto;
      max-height: 100%;
    }
  }

  .modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .dark .modal-content {
    @apply bg-black;
  }

  .player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
